import Layout from 'common/layouts/Layout';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import {
  H2,
  LegalSection,
  LegalWrapper,
  Li,
  Ol,
  Paragraph,
} from 'components/pages/legal/shared';
import { SEO } from 'components/SEO';
import React, { FC } from 'react';

interface ReferralProps {}

const Referral: FC<ReferralProps> = () => (
  <Layout>
    <Navigation />
    <PageWrapper>
      <LegalWrapper>
        <LegalSection>
          <H2 medium>Referral Program</H2>
          <Paragraph medium>Terms and Conditions:</Paragraph>
          <Paragraph>
            <Ol>
              <Li>
                <b>Eligibility:</b> The referral program is open to existing
                clients of Retirable who have an active account in good
                standing. Referred friends must be new clients who have not
                previously registered with Retirable. Valid from 8/1/2024 —
                12/31/2024.
              </Li>
              <Li>
                <b>Referral Reward:</b> Referring clients will receive a $250
                cash reward once the referred client successfully opens an
                investment account with Retirable. The reward will be paid to
                the referring client&apos;s Retirable Spend or Save account. The
                reward will be processed within 30 days after eligibility.
              </Li>
              <Li>
                <b>Free Trial Period:</b> The 90-day free trial is applicable
                only to the wealth management services offered by Retirable and
                is available to new clients only. After the 90-day period,
                standard wealth management fees will apply unless the client
                cancels the service. Clients can cancel at any time.
              </Li>
              <Li>
                <b>Referral Link:</b> Referrals must be made through the unique
                referral link provided. Referrals not made through this link
                will not be eligible for the reward.
              </Li>
              <Li>
                <b>Limitations:</b> There is no limit to the number of referrals
                a client can make; however, rewards are only applicable if the
                referred client meets all eligibility criteria and successfully
                completes the free trial period.
              </Li>
              <Li>
                <b>Modification and Termination:</b> Retirable reserves the
                right to modify or terminate the referral program at any time
                without prior notice. In the event of program termination, all
                pending rewards will still be honored according to the terms in
                place at the time of the referral.
              </Li>
              <Li>
                <b>Fraudulent Activity:</b> Retirable reserves the right to
                disqualify any referrals or participants that it deems to be
                fraudulent or in violation of these terms and conditions.
              </Li>
              <Li>
                <b>Tax Implications:</b> Referral rewards may be subject to tax
                reporting and withholding. Retirable is not responsible for any
                tax implications or obligations incurred by participants in the
                referral program. Participants are advised to consult with their
                tax advisor regarding the tax treatment of referral rewards.
              </Li>
            </Ol>
          </Paragraph>
          <Paragraph medium>
            By participating in the referral program, you agree to these terms
            and conditions.
          </Paragraph>
        </LegalSection>
      </LegalWrapper>
    </PageWrapper>
  </Layout>
);

export default Referral;

export const Head = () => (
  <SEO
    title="Referral Program — Retirable"
    description="View the referral policy for Retirable."
  />
);
